.nav {
  position: fixed;
  top: 0;
  width: 100%;
  height: 1.5rem;
  z-index: 1;
  background: #ffffff0a;
  padding-top: 25px;
  box-sizing: content-box;
  transition-timing-function: ease-out;
  transition: all 0.5s;
  border-bottom: solid 1px #ffffff1a;
  backdrop-filter: blur(9px);
}
.nav__wrapper {
  max-width: 1600px;
}
.nav__black {
  transform: translateY(-100%);
}

.nav__content {
  display: flex;
  justify-content: space-between;
}

.nav__logo {
  position: fixed;
  left: 20px;
  object-fit: contain;
  cursor: pointer;
  align-self: center;
}

.logo__nasa {
  font-size: 1.6em;
  color: #6db3c8;
  transition: font-size linear 0.3s;
}
.logo__cam {
  font-size: 20px;
  color: #ffffff;
  transition: font-size linear 0.3s;
  letter-spacing: normal;
}

.nasa__scroll {
  font-size: 1.2em;
}
.cam__scroll {
  font-size: 1em;
}

.nav__menu {
  color: #ffffff;
  position: fixed;
  right: 20px;
  cursor: pointer;
  font-size: 1em;
  align-self: center;
}
