.banner {
  background: linear-gradient(177deg, black, #001b1b);
  min-height: 24rem;
  display: grid;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: stretch;
}

.banner_container {
  background: url('../assets/images/mars-header.png');
  background-position: bottom;
  background-size: cover;
}

.banner__card {
  padding-top: 9rem;
  padding-bottom: 9rem;
  display: grid;
  align-content: center;
  justify-items: center;
}

.banner__content {
  text-align: center;
  display: grid;
  align-content: space-evenly;
  align-items: center;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(0.3rem);
  border-radius: 10px;
  padding: 1em;
  border: 1px solid #ffffff14;
  justify-items: center;
  margin: 1rem 4rem 0 4rem;
}

.banner__title {
  font-family: 'Jura', sans-serif;
  font-weight: 500;
  font-size: min(max(36px, 4vw), 70px);
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  text-align: center;
  line-height: 1;
  align-content: stretch;
  justify-content: center;
  margin: 0rem 0 3rem 0;
  max-width: 90%;
}
.banner__title span {
  font-weight: 700;
}
.banner__text {
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.4;
  max-width: 85%;
}
.banner__subtext {
  font-size: 0.75rem;
  display: grid;
  text-transform: uppercase;
  text-align: right;
  padding: 1rem;
}

.banner__btn {
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: 700;
  background: none;
  border: 1px solid #519591;
  border-radius: 4px;
  padding: 0.8rem;
  color: #77cf79;
  margin-top: 1rem;
  cursor: pointer;
  vertical-align: middle;
  transition: all 0.3s;
}

.banner__btn:hover {
  transition: all 0.3s;
  background: #ffffff10;
  color: #519591;
}

.banner__picker {
  display: flex;
  justify-items: center;
  position: absolute;
  bottom: 0;
}
