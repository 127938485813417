.stats__container {
  text-align: center;
  padding-bottom: 1rem;
  background-color: #02222c;
  font-family: 'Jura', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(30deg, #001d26, transparent);
  border-bottom: 1px solid #294964;
}

.stats__card {
  max-width: 85%;
  --w: 800px;
  --n: 3;
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(
      clamp(100%/ (var(--n) + 1) + 0.1%, (var(--w) - 100vw) * 1000, 100%),
      1fr
    )
  );
  gap: 1rem;
}

.card__content {
  border-radius: 5px;
  background: #121212;
  padding: 1.2rem;
  background: linear-gradient(33deg, #31516e, #568c90);
  border: 1px solid #063f55;
}

hr {
  width: 100%;
  display: block;
  margin: 0;
  padding: 0;
  border-color: #00daff0d;
}

h1,
h2,
h3,
h4 {
  font-family: 'Jura', sans-serif;
  text-align: center;
}
h1 {
  font-weight: 700;
  font-size: 2rem;
  padding: 0 1rem;
}

.stats__container h1 {
  margin-bottom: 20px;
  border-top: 1px solid #8eade736;
  width: 100%;
  border-bottom: 1px solid #8eade736;
}

h3 {
  font-weight: 500;
  font-size: 1.8rem;
}

.card__text {
  line-height: 1.5em;
}

.line__height__sm {
  line-height: 1.2rem;
  font-size: 0.9em;
}

.mission__stat {
  font-weight: 600;
  color: #67e9ff;
  text-transform: uppercase;
  font-size: 1.1em;
}

.credits {
  font-size: 0.75rem;
  text-transform: uppercase;
  padding: 1rem 0;
}
