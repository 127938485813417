.date__summary {
  text-align: center;
  padding: 1rem;
}

.selected__date {
  text-transform: uppercase;
  font-size: 1.2rem;
  padding: 0.2rem;
  font-weight: 700;
}
.selected__sol {
  text-transform: uppercase;
  font-size: 0.9rem;
}

.returned__details {
  text-transform: uppercase;
  letter-spacing: 0.07rem;
  font-weight: 300;
}

.returned__text {
  text-transform: capitalize;
  font-weight: 600;
  letter-spacing: 0;
}

.returned__empty {
  color: red;
}
