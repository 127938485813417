:root {
  --main-bg-color: #182a34;
}
html {
  font-size: min(max(15px, 4vw), 20px);
  font-size: calc(15px + (20 - 15) * ((100vw - 300px) / (1600 - 300)));
}
.app {
  /* background-color: var(--main-bg-color); */
  background: radial-gradient(#052832, rgb(0 29 39));
}

.material-icons .md-16 {
  font-size: 16px;
}
.material-icons .md-18 {
  font-size: 18px;
}
.material-icons .md-24 {
  font-size: 24px;
}
.material-icons .md-36 {
  font-size: 36px;
}
.material-icons .md-48 {
  font-size: 48px;
}

.material-icons .md-dark {
  color: rgba(0, 0, 0, 0.54);
}
.material-icons .md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26);
}

.material-icons .md-light {
  color: rgba(255, 255, 255, 1);
}
.material-icons .md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3);
}
