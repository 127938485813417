.photo__gallery__container {
  margin: 1vh 3vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.6rem;
  line-height: 0;
}
.button__holder {
  display: flex;
  justify-content: center;
  line-height: 0;
  align-items: center;
  margin-top: 20px;
}
.modal__button {
  padding: 0.6rem;
  width: fit-content;
  border-radius: 4px;
  color: #ffffff;
  border: solid #647b7a 1px;
  cursor: pointer;
  font-size: 0.8rem;
  margin: 0 1rem;
  transition: all 0.3s;
}
.modal__button:hover {
  background: #ffffff2d;
  border: solid #5195914f 1px;
}
.nav__icon {
  font-size: 20px;
  vertical-align: bottom;
  line-height: 0;
  padding: 0.2rem 0rem;
  vertical-align: sub;
}
.image__wrap {
  overflow: hidden;
  height: 0;
  padding: 35% 0;
  position: relative;
  opacity: 1;
}

.image__wrap img {
  width: 100%;
  max-width: 120%;
  position: absolute;
  object-fit: cover;
  height: 100%;
  top: 0;
  left: 0;
  border: 3px solid #26363f;
  border-radius: 8px;
  cursor: zoom-in;
}

@media screen and (min-width: 1100px) {
  .photo__gallery__container {
    grid-template-columns: 1fr 1fr 1fr;
  }
} ;
